import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Container } from '../styles';
import { PageHeader } from '../components/Commons';

const AboutPage = () => (
	<Layout>
		<SEO title="Sobre" />
        <PageHeader title="Sobre" subtitle="Lorem ipsum lorem lorem lorem lorem lorem" />

        <Container>

        </Container>
	</Layout>
);

export default AboutPage;
